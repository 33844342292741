import React, { Component } from "react";
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

class EvoPage extends Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount() {

    trackCustomEvent({
      category: "Conversion",
      action: "Evo magazine",
      label: "Evo traffic source"
    });
  
    window.location.href="/";
  }

  render() {
    return (
      <p>One moment...</p>
    );
  }
}
export default EvoPage;


